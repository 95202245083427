import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CreateDevices() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  
  function handleChangeImage(event) {
    if (event.target.name === "image") {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          const base64WithoutHeader = base64String.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          );
          setInputs((values) => ({
            ...values,
            image: base64WithoutHeader,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post("https://www.posstore.az/posstore/dashboard.php/save", inputs)
      .then(function (response) {
        navigate("/dashboard/devices");
      });
  };

  return (
    <div>
      <h1>Cihaz əlavə et</h1>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th>
                <label>Ad: </label>
              </th>
              <td>
                <input type="text" name="name" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Qiymət: </label>
              </th>
              <td>
                <input type="number" name="price" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Endirimli Qiymət: </label>
              </th>
              <td>
                <input type="number" name="discountPrice" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Model: </label>
              </th>
              <td>
                <input type="text" name="model" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Prosessor: </label>
              </th>
              <td>
                <input type="text" name="processor" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Nəsil: </label>
              </th>
              <td>
                <input type="text" name="generation" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Ram: </label>
              </th>
              <td>
                <input type="text" name="ram" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>SSD: </label>
              </th>
              <td>
                <input type="text" name="ssd" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>GHZ: </label>
              </th>
              <td>
                <input type="text" name="ghz" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Ekran ölçüsü: </label>
              </th>
              <td>
                <input type="text" name="size" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Touchscreen: </label>
              </th>
              <td>
                <input type="text" name="touchscreen" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Bluetooth: </label>
              </th>
              <td>
                <input type="text" name="bluetooth" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>WI-FI: </label>
              </th>
              <td>
                <input type="text" name="wifi" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>USB: </label>
              </th>
              <td>
                <input type="text" name="usb" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>LAN: </label>
              </th>
              <td>
                <input type="text" name="lan" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Rəng: </label>
              </th>
              <td>
                <input type="text" name="color" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Stokdakı sayı: </label>
              </th>
              <td>
                <input type="text" name="count" onChange={handleChange} />
              </td>
            </tr>
            <tr> 
              <th>
                <label>Kateqoriya: </label>
              </th>
              <td>
                <select name="category" onChange={handleChange}>
                  <option value="">Seçin</option>
                  <option value="Barkod Oxuyucu">Barkod Oxuyucu</option>
                  <option value="Barkod Printer">Barkod Printer</option>
                  <option value="Barkod Tərəzi">Barkod Tərəzi</option>
                  <option value="Card Reader">Card Reader</option>
                  <option value="Fiyatgör">Fiyatgör</option>
                  <option value="Kağızlar">Kağızlar</option>
                  <option value="Komputerlər">Komputerlər</option>
                  <option value="Pul qutuları">Pul qutuları</option>
                  <option value="Touchscreen">Touchscreen</option>
                  <option value="Çek Printer">Çek Printer</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>
                <label>Şəkil: </label>
              </th>
              <td>
                <input type="file" name="image" onChange={handleChangeImage} />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <button>Yadda saxla</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}
