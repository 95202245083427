import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../common/Back";
import img from "../images/about.jpg";
import Modal from "./Modal"; // Import the Modal component
import { toast } from "react-toastify";
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';
import { FaShoppingCart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/orebiSlice";
 
export default function ProductDetail() {

  const dispatch = useDispatch();
 
  const form = useRef();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState(""); 
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");

  const params = useParams();
  const params2 = Number(params.id);

  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, [params2]);

  const getProducts = async () => {
    try {
      const response = await fetch("https://www.posstore.az/posstore/dashboard.php/", {
        credentials: "omit",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok"); 
      }

      const data = await response.json();
      setItems(data.filter((item) => Number(item.id) === params2)); // `item.id`'yi sayıya çeviriyoruz
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitOrder = async () => {

    let inputs1 = {
      to_email: clientEmail,
      message: 'Ad: ' + clientName + ', Email: ' + clientEmail + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + list[0].name + ', Qiymət: ' + (list[0].discountPrice != 0 ? list[0].discountPrice : list[0].price) + " AZN"
    };

    let inputs11 = {
      to_email: 'namelum',
      message: 'Ad: ' + clientName + ', Email: ' + 'namelum' + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + list[0].name + ', Qiymət: ' + (list[0].discountPrice != 0 ? list[0].discountPrice : list[0].price) + " AZN"
    };
    

    if (clientEmail) {  // Eğer clientEmail boş değilse
      emailjs
        .send('service_fhagip7', 'template_a93qaml', inputs1, 'oPV8IO9EVD-P7f5sV')
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    } else {
      emailjs
      .send('service_fhagip7', 'template_a93qaml', inputs11, 'oPV8IO9EVD-P7f5sV')
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    }




    let inputs2 = {
      name: clientName,
      email: clientEmail,
      phone: clientPhone,
      address: clientAddress,
      products: list[0].name,
      totalAmt: list[0].discountPrice != 0 ? list[0].discountPrice : list[0].price,
    };
    



    try {
      axios.post("https://www.posstore.az/posstore/orders.php/", inputs2).then(function (response) {
        navigate("/");
        swal("Sifarişiniz tamamlandı");
        setclientName("");
        setclientEmail("");
        setclientPhone("");
        setclientAddress("");
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Sifariş gönderilirken bir hata oluştu:", error);
    }
  };

  return ( 
    <>
      <Back name="Məhsul haqqında" title="Avadanlıqlar-Məhsul haqqında" cover={img} />
      <div className="container" style={{ margin: "0 auto", padding: "3rem 0" }}>
        <div className="flex-center" style={{ display: "flex", justifyContent: "space-between" }}>
        
            {isLoading ? (
              <div style={{ height:'200px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
  <img style={{ width: '50px', height: '50px' }} src="/./loading.gif" alt="Loading" />
</div>

            ) : list.length > 0 ? (
              list.map((val, index) => {
                const { id, image, name, category,price, discountPrice, model, processor,count, generation, ram, ssd, ghz, touchscreen, bluetooth, wifi, usb, lan, color, size } = val;
                return (
                    <>
              
                 <div style={{width:'50%'}}>
                 <div className='box'>
              <div className='img'>
                <img src={`data:image/jpeg;base64,${image}`} alt='' />
                <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{name}</h4>
              </div>
            </div>
                 </div>
                    <div style={{ width:"40%"}} className="product-info">
                      <h4 style={{ fontSize: "20px",  marginBottom: "1rem" }}>Kateqoriya: {category}</h4>
                      {model && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Model: {model}</h4>}
{processor && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Prosessor: {processor}</h4>}
{generation && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Nəsil: {generation}</h4>}
{ram && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Ram: {ram}</h4>}
{ssd && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>SSD: {ssd}</h4>}
{ghz && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>GHZ: {ghz}</h4>}
{size && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Ekran ölçüsü: {size}</h4>}
{touchscreen && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Touchscreen: {touchscreen}</h4>}
{bluetooth && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Bluetooth: {bluetooth}</h4>}
{wifi && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>WI-FI: {wifi}</h4>}
{usb && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>USB: {usb}</h4>}
{lan && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>LAN: {lan}</h4>}
{color && <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>Rəng: {color}</h4>}

                      <h4 style={{ fontSize: "20px", marginBottom: "1rem" }}>
                       Stokdakı sayı: {count > 0 ? count : 'Stokda yoxdur'}
                       </h4>
                      {discountPrice > 0 ? <p style={{fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem", color:'green'}}><span style={{color:"red"}}><del>{price} AZN</del></span> {discountPrice} AZN</p> :
                        <p style={{fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem"}}> {price} AZN</p>}
                      <button
  onClick={() => setIsModalOpen(true)}
  style={{
    marginTop: "1rem",
    padding: "1rem 2rem",
    backgroundColor: "#F1592A",
    color: "white",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "340px",
    cursor: count == 0 ? "not-allowed" : "pointer",
    opacity: count == 0 ? 0.5 : 1,
  }}
  disabled={count == 0}  // Eğer count 0 ise butonu devre dışı bırak
>
  Sifariş ver
</button>

<button
  style={{
    marginTop: "1rem",
    padding: "1rem 2rem",
    backgroundColor: count == 0 ? "#F1592A" : "#F1592A", // Keep the color consistent
    color: "white",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "340px",
    cursor: count == 0 ? "not-allowed" : "pointer",
    opacity: count == 0 ? 0.5 : 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  onClick={() => {
    if (count > 0) {
      dispatch(
        addToCart({
          id: id,
          name: name,
          quantity: 1,
          image: image,
          price: price,
          discountPrice: discountPrice,
        })
      );
    }
  }}
  disabled={count == 0} // Disable the button if count is 0
>
  Səbətə əlavə et
  <span style={{ marginLeft: "0.2rem" }}>
    <FaShoppingCart />
  </span>
</button>




                    </div></>
                );
              })
            ) : (
              <p>No data found</p>
            )}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} style={{ color: "white", cursor: "pointer" }}>
              &#10005;
            </button>
          </div>
          <form ref={form} onSubmit={handleSubmitOrder}>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ad, soyad*</label>
              <input
               name="user_name"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Adınızı daxil edin"
                onChange={(event) => setclientName(event.target.value)}
                value={clientName}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>E-mail</label>
              <input
              name="user_email"
                type="email"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Emailinizi daxil edin"
                onChange={(event) => setclientEmail(event.target.value)}
                value={clientEmail}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Əlaqə nömrəsi*</label>
              <input
              name="user_number"
                type="number"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Telefon nömrənizi daxil edin"
                onChange={(event) => setclientPhone(event.target.value)}
                value={clientPhone}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ünvan*</label>
              <input
              name="user_address"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Ünvanınızı daxil edin"
                onChange={(event) => setclientAddress(event.target.value)}
                value={clientAddress}
                required
              />
            </div>
            <div>
            <div className="flex-center" style={{ display: "flex", justifyContent: "space-between" }}>
        
        {isLoading ? (
          <div style={{ height:'200px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
<img style={{ width: '50px', height: '50px' }} src="/./loading.gif" alt="Loading" />
</div>

        ) : list.length > 0 ? (
          list.map((val, index) => {
            const { id, image, name, category,price, discountPrice, model, processor,count, generation, ram, ssd, ghz, touchscreen, size } = val;
            return (
                <>
                <div style={{ marginBottom: "1rem", color: discountPrice > 0 ? "green" : "black" }}>
    {discountPrice > 0 ? (
     <p>Məhsul endirimdədir! <span style={{color:'red'}}> <del>{price} AZN</del> </span>{discountPrice} AZN</p>


    ) : (
      <p>Məhsulun qiyməti: {price} AZN</p>
    )}
  </div>
               </>
            );
          })
        ) : (
          <p>No data found</p>
        )}
    </div>

              <button
                type="submit"
                style={{
                  padding: "0.75rem 1.5rem",
                  backgroundColor: "#F1592A",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Sifariş ver
              </button>
            </div>
          </form>
      </Modal>
    </>
  );
}