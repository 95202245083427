import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function DashboardDevices() {
  const naviget = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }

  const user = localStorage.getItem("user");

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    setLoading(true); // Set loading to true before fetching data
    axios
        .get("https://www.posstore.az/posstore/dashboard.php") // Kategori parametresi olmadan çağır
        .then(function (response) {
            setProducts(response.data);
            setLoading(false); // Set loading to false after data is fetched
        })
        .catch(function (error) {
            console.error("Error fetching products:", error);
            setLoading(false); // Set loading to false even if there's an error
        });
}


  const deleteUser = (id) => {
    axios
      .delete(`https://www.posstore.az/posstore/dashboard.php/${id}/delete`, {
        withCredentials: false
    })
      .then(function (response) {
        getProducts();  
      });
  };

  const updateUserLink = (id) => {
    return `/dashboard/devices/update/${id}`; // Güncelleme sayfasına yönlendiren URL
  };
  

  return (
    <>
    <DashboardHeader/>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <li>
          <Link style={{color:'black'}} to="/dashboard/devices/create">Cihaz əlavə et</Link>
        </li>
        <div>
          <h1>Cihazlar</h1>
          {loading ? ( // Conditionally render based on loading state
            <img style={{width:'50px', height:'50px'}} src="/./loading.gif"></img>
          ) : (
            <table>
              <thead>
                <tr>
                <th>Actions</th>
                  <th>ID</th>
                  <th>Ad</th>
                  <th>Kateqoriya</th>
                  <th>Qiymət</th>
                  <th>Endirimli qiymət</th>
                  <th>Model</th>
                  <th>Prosessor</th>
                  <th>Nəsil</th>
                  <th>SSD</th>
                  <th>GHZ</th>
                  <th>Ram</th>
                  <th>Ölçü</th>
                  <th>Touchscreen</th>
                  <th>Bluetooth</th>
                  <th>WI-FI</th>
                  <th>USB</th>
                  <th>LAN</th>
                  <th>Rəng</th>
                  <th>Stokdakı sayı</th>
                  <th>Şəkil</th>
                </tr>
              </thead>
              <tbody>
                {products.map((user, key) => (
                  <tr key={key}>
                    <td style={{display:'flex'}}>
                      <button style={{marginRight:'5px'}} onClick={() => deleteUser(user.id)}>Sil</button>
                      <Link to={updateUserLink(user.id)}>
                      <button>Yenilə</button>
                    </Link>
                    </td>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.category}</td>
                    <td>{user.price}</td>
                    <td>{user.discountPrice}</td>
                    <td>{user.model}</td>
                    <td>{user.processor}</td>
                    <td>{user.generation}</td>
                    <td>{user.ssd}</td>
                    <td>{user.ghz}</td>
                    <td>{user.ram}</td>
                    <td>{user.size}</td>
                    <td>{user.touchscreen}</td>
                    <td>{user.bluetooth}</td>
                    <td>{user.wifi}</td>
                    <td>{user.usb}</td>
                    <td>{user.lan}</td>
                    <td>{user.color}</td>
                    <td>{user.count}</td>
                    <td>Şəkil url</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
