import React from "react";
import "./App.css";
import Pages from "./components/pages/Pages";
import * as JivoSite from "react-jivosite";  // Jivo widget'ını dahil ediyoruz

function App() {
  return (
    <>
      <Pages /> 

      {/* Jivo widget */} 
      <JivoSite.Widget id="DmZkxvBxGI" />
    </>
  );
}  

export default App;
