import React, { useState } from 'react';
import axios from 'axios';

const SoftwareForm = () => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [extra, setExtra] = useState('');
    const [price, setPrice] = useState('');
    const [images, setImages] = useState(Array(10).fill(null)); // 10 görsel için
    const [video, setVideo] = useState(null);
    const [message, setMessage] = useState('');

    const handleImageChange = (index, file) => {
        const newImages = [...images]; 
        newImages[index] = file;
        setImages(newImages); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('text', text);
        formData.append('extra', extra);
        formData.append('price', price);
        
        images.forEach((image, index) => {
            if (image) {
                formData.append(`image${index + 1}`, image);
            }
        });

        if (video) {
            formData.append('video', video); 
        }

        try {
            const response = await axios.post('https://www.posstore.az/posstore/softwareProgram.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error uploading:', error);
            setMessage('Error uploading data');
        }
    };

    return (
        <div>
            <h2>Yeni Proqram təminatı</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Başlıq" value={title} onChange={(e) => setTitle(e.target.value)} required />
                <input placeholder="Mətn" value={text} onChange={(e) => setText(e.target.value)} required></input>
                <input type="text" placeholder="Extra" value={extra} onChange={(e) => setExtra(e.target.value)} />
                <input type="number" placeholder="Qiymət" value={price} onChange={(e) => setPrice(e.target.value)} required />
                
                {[...Array(10)].map((_, i) => (
                    <div key={i}>
                        <label>Image {i + 1}</label>
                        <input type="file" onChange={(e) => handleImageChange(i, e.target.files[0])} />
                    </div>
                ))}

                <label>Video</label>
                <input type="file" accept="video/*" onChange={(e) => setVideo(e.target.files[0])} />

                <button type="submit">Yazılımı Ekle</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default SoftwareForm;

